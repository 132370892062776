import React from 'react';
import { Alert } from 'react-bootstrap';
import { Color } from 'react-bootstrap/esm/types';

interface Props{
    dismissible: boolean,
    message: string
}

export const SKAAlert = (props: Props, status:String) => {
    
    const {dismissible, message} = props;
    switch(status){
        case 'error':
            return (
        
                <Alert
                    variant="warning"
                    dismissible={dismissible}
                >
                    {message}
                </Alert>
            );
        break;
        case 'success':
            return (
        
                <Alert
                    variant="success"
                    dismissible={dismissible}
                >
                    {message}
                </Alert>
            );
        break;
    }
    
   
}