import {FunctionComponent, ReactElement} from "react";
import { Badge, Container,Image } from "react-bootstrap";
import ListGroup from 'react-bootstrap/ListGroup';
import Card from 'react-bootstrap/Card';

import "./home.css"; 
const Home: FunctionComponent = () => {
  return (
    <Container>
    <div className="shadow p-3 mb-5 bg-white rounded justify-content-around">
  
  <Card >
      <Card.Body>
      
        <Card.Title as="h3"  className="headerStyle" ><Image src="/img/Team.svg" width="30px" height="30px" roundedCircle  /> Who are we?</Card.Title>
       
        <Card.Text>
      
        <p className="font-weight-normal fs-5 ">
        SKA Environmental is a Houston based small business owned by women. The company was established with the intention of providing an enhanced inspection alternative that places an emphasis on its quickness, reliability, and exceptional communication. In the field of Environmental Site inspection (Phase 1- ESA) and Property Condition Assessment (PCA), our inspectors have accumulated a significant amount of experience across many years.

            </p>
         
        </Card.Text>
        
      </Card.Body>
    </Card>
<br></br>
    <Card >
      <Card.Body>
      
        <Card.Title as="h3"  className="headerStyle"> <Image src="/img/experience.svg" width="30px" height="30px" roundedCircle/> We have: </Card.Title>
       
        <Card.Text>
      
        <p className="font-weight-normal fs-5 ">
       

        <ListGroup variant="flush">
      <ListGroup.Item> • Performed more than 1,000 in-depth site reconnaissance's to obtain firsthand data for Phase I Environmental Site Assessments (ESAs) and Property Condition Assessments (PCAs). Gathered information to classify and measure possible pollutants.
        
      </ListGroup.Item>
      <ListGroup.Item>• Contributed to projects which involved HUD (Housing and Urban Development), Freddie Mac and Fannie Mae complaint inspection, and for other major financial organizations.</ListGroup.Item>
      <ListGroup.Item>• Experience on variety of site types, such as manufacturing facilities, dry cleaners, petrol stations and residential buildings. We also have experience in  Phase II site investigations, conceptual site models, and comprehensive Phase I environmental site assessment studies.</ListGroup.Item>
      <ListGroup.Item>• Carried out thorough soil, soil vapor, and groundwater sampling; manage groundwater monitoring; supervise and direct well installations and development; and manage well rehabilitation.</ListGroup.Item>
    </ListGroup>

            </p>
         
        </Card.Text>
        
      </Card.Body>
    </Card>
    <br></br>
    <Card >
      <Card.Body>
      
        <Card.Title as="h3"  className="headerStyle"><Image src="/img/why.svg" width="30px" height="30px" roundedCircle/> Why us:</Card.Title>
       
        <Card.Text>
      
        <p className="font-weight-normal fs-5 ">
       

        We will provide <b><i>STRESS-FREE DELIVERY</i></b> ,  we recognize that you have priorities to attend to.  You may rely on our highly skilled inspectors and professional personnel to fulfil your requirements in a fast, precise and professional manner.

        </p>
        <p className="font-weight-normal fs-5 ">
    We will <b><i>ECONOMIZE</i></b> your time and cost. You won't have to worry about gas, plane tickets, hotel, etc. because our team can handle your request anywhere in and around Houston, including the following cities: Austin, Galveston, College Station, Waco, Beaumont, Victoria, Lufkin, Brenham, Dallas, Fort Worth, Tyler, Lake Charles, Shreveport, Killeen, Temple, New Braunfels, Port Arthur, Nacogdoches, etc.  For a small fee, you can have your inspection done while you relax in your recliner. In addition, there are no upfront fees or annual commitments.Our team is committed to executing project requests promptly, so you may better manage your time.  Instead of doing important inspections outside of office hours, your management can focus on getting things done in the office.


    </p>
         
        </Card.Text>
        
      </Card.Body>
    </Card>
    </div>
  
    </Container>
  )
}
export default Home;