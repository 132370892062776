
import {FunctionComponent, ReactElement} from "react";
import { Badge, Col, Row } from "react-bootstrap";




const Footer: FunctionComponent = () => {
  return <footer>
    <hr></hr>
    <div className="container text-center">
    <p className="text-muted credit" >All right reserved. &copy; Copyright {(new Date().getFullYear())} SKA environmental </p>
  </div>
  </footer>
}
export default Footer;