

import {FunctionComponent, ReactElement} from "react";
import { Image, Card, Container, Button, Alert } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import "../services/Services.css"; 
import { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { SKAAlert } from "../../components/common/Alert/SKAAlert";
import "./Contact.css"

const validateEmail=(email:string)=>{
  const re = new RegExp(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return re.test(email)

}
const Contact: FunctionComponent = () => {
  const button = document.createElement('button');
  button.className="buttonBG btn btn-primary btn-sm";
  button.ariaLabel="Close alert";
  button.innerText="Close"
  button.addEventListener('click', () => {    
    resetContent();
    button.className="hide";
    });
  const [show, setShow] = useState(false);
  const[show2,setShow2]=useState(true);
  const resetValue=(emailid:HTMLInputElement,message:HTMLInputElement,author:HTMLInputElement)=>{
    emailid.value="";
    message.value="";
    author.value="";
  }
 const resetContent=()=>{
  var heading=document.getElementById("emailAlertHeading") as HTMLInputElement;
  var text=document.getElementById("emailAlertText") as HTMLInputElement;
  heading.innerHTML='';
  text.innerHTML=''
 }
  const handleemailSubmit=async ()=>{   
    button.className="hide";
    resetContent();
    const emailid=document.getElementById("exampleForm.email") as HTMLInputElement;
    const message=document.getElementById("exampleForm.message") as HTMLInputElement;
    const author=document.getElementById("exampleForm.Name") as HTMLInputElement;
   
   if(validateEmail(emailid.value)){    
     button.className="buttonBG btn btn-primary btn-sm";
    button.ariaLabel="Close alert";
    button.innerText="Close"
    var heading=document.getElementById("emailAlertHeading") as HTMLInputElement;
    var text=document.getElementById("emailAlertText") as HTMLInputElement;
    var alertBox=document.getElementById("emailAlert") as HTMLInputElement;
    alertBox.appendChild(button);
    
    
   const url = "message.php?id=" + emailid.value + "&Name=" + author.value + "&Message=" + message.value;
    fetch(url)
    .then(response => response.json())
    .then(data => {console.log(data);
      
      heading.innerHTML='Message has been successfully sent.';
      text.innerHTML='Message has been sent, we will get back to you shortly.'
      
      resetValue(emailid,message,author);
        
       const v=SKAAlert({dismissible:true,message:"Successfully sent your message"},"success");
    })
    .catch(error => {console.error(error)
      
      heading.innerHTML="Oh snap! we where unable to send the message.";
      text.innerHTML="Since we could not send the email, could you please call (832)-779-6273."
      
      
      resetValue(emailid,message,author);
    });
    
   }else{
    setShow(true);
   }
   

    }
  
  return (
    <Container>
    <div className="shadow p-3 mb-5 bg-white rounded">
  <Card >
      <Card.Body>
      
        <Card.Title as="h1" className="headerStyle"  ><Image src="/img/email.svg" width="30px" height="30px" roundedCircle  /> Contact Us</Card.Title>
       
        <Card.Text>
          <Form >
          <Form.Group className="mb-3" controlId="exampleForm.Name">
          <Form.Label>Your Name:</Form.Label>
          <Form.Control type="textbox"  />
        </Form.Group>


        <Form.Group className="mb-3" controlId="exampleForm.email">
          <Form.Label>Your email address:</Form.Label>
          <Form.Control type="email" />
          
          <Alert variant="danger"  show={show}  onClose={() => setShow(false)}  dismissible>
            <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
            <p>
              Change the email id and try again.We would want to reach out to in case of any question that we may have.
              It is best if you can share your email id.
            </p>
          </Alert>
        
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.message">
          <Form.Label>Your Message:</Form.Label>
          <Form.Control as="textarea" rows={10} />
        </Form.Group>
      </Form>
         
        </Card.Text>
        <div className="divBorder"  >
      <Row >
        <Col>
          <Button href="/contactus" variant="primary" size="lg" className="buttonBG" onClick={(e)=>{e.preventDefault();handleemailSubmit()}}>Send email</Button> 
        <Alert variant="primary"  id="emailAlert" className="alert" show={show2}  onClose={() => {setShow2(true);resetContent();}}   >
        <Alert.Heading id="emailAlertHeading"></Alert.Heading>
            <p id="emailAlertText">
              
            </p>
          </Alert>
        </Col>
      </Row>
    </div>
      </Card.Body>
    </Card>
    </div>
    
    </Container>
  )
}
export default Contact;