import { render } from "@testing-library/react";
import { Container, Nav, Navbar } from "react-bootstrap";

function Navigation(){
    return(
      <Navbar  expand="lg" bg="primary" data-bs-theme="light" className="bg-body-tertiary">
      <Container fluid>
        <Navbar.Brand href="/">
        <img
                alt=""
                src="/img/Logo1.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
       SKA environmental
       
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end">
          <Nav>
        <Nav.Link eventKey="services" href="/services">our services</Nav.Link>

         
          <Nav.Link eventKey="contactus" href="/contactus">contact us</Nav.Link>
       
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
        

    );

}
export default Navigation;