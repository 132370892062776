

import {FunctionComponent, ReactElement} from "react";
import { Card, Container ,Image, ListGroup} from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import "./Services.css"; 

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col'
const Services: FunctionComponent = () => {
  return  (
  <Container>
  <div className="shadow p-3 mb-5 bg-white rounded">
<Card >
    <Card.Body>
    
      <Card.Title as="h3" className="headerStyle" ><Image src="/img/services.svg" width="30px" height="30px" roundedCircle  />Our Services</Card.Title>
     
      <Card.Text>
      




    <ListGroup as="ol" >
      <ListGroup.Item as="li">Environmental Field Inspections</ListGroup.Item>
      <ListGroup.Item as="li">Property Condition Assessment Inspection</ListGroup.Item>
      <ListGroup.Item as="li">Exterior and Interior Site Reconnaissance</ListGroup.Item>
      <ListGroup.Item as="li">Detailed Reporting and Photos of Site Conditions</ListGroup.Item>
    </ListGroup>


    
      </Card.Text>
      <div className="divBorder"  >
      <Row >
        <Col><Button href="/contactus" variant="primary" size="lg" className="buttonBG">Contact Us</Button></Col>
      </Row>
    </div>
 
    </Card.Body>
  </Card>
  </div>
  
  </Container>
  )
}
export default Services;