import React from 'react';
import { useEffect } from "react"

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Col, Container, Row } from 'react-bootstrap';
import Navigation from './components/common/NavigationBar/Navigation';
import Contact from './Pages/contact/Contact';
import Services from './Pages/services/Services';
import Home from './Pages/home/home';
import { BrowserRouter,Route,Routes } from 'react-router-dom';
import Footer from './Pages/Footer/Footer';




let email_id:string="info@skaenvironmental.com"


const App:React.FC=() =>{
  document.title = "skaenvironmental";
  return (
    
 <Container  >
  <Row>
    <Col>
      <Navigation  />
    </Col>
  </Row>
  <Row>
    <Col>
    <img
                alt=""
                src="/img/Banner3.png"
              width="100%"
              
                className="d-inline-block align-top"
              />
    </Col>
  </Row>
<Row>
  <Col>
  <main role='main'>
  
  <Routes>  
      <Route path="" element={<Home/>}/>
      <Route path="contactus" element={<Contact/>}/>
      <Route path='/services' element={<Services/>}/>
    </Routes>
  </main>
  </Col>
  </Row>
  <Footer/>
 </Container>
  );


}

export default App;